export default {
  // Create your Bouygues Telecom account
  title: {
    id: 'checkout_services_bouygues_sign_up_form_title',
    defaultMessage: 'Create your Bouygues Telecom account',
  },
  emailInputDescription: {
    id: 'checkout_services_bouygues_sign_up_form_email_input_description',
    defaultMessage: 'You’ll get a verification code at this address.',
  },
  emailInputLabel: {
    id: 'checkout_services_bouygues_sign_up_form_email_input_label',
    defaultMessage: 'Email',
  },
  emailInputErrorRequired: {
    id: 'checkout_services_bouygues_sign_up_form_email_input_error_required',
    defaultMessage: 'The email field is required',
  },
  emailInputErrorInvalid: {
    id: 'checkout_services_bouygues_sign_up_form_email_input_error_invalid',
    defaultMessage: 'This email seems to be invalid',
  },
  emailInputErrorAlreadyUsed: {
    id: 'checkout_services_bouygues_sign_up_form_email_input_error_already_used',
    defaultMessage:
      'The email address you entered is already in use. Please try logging in instead',
  },
  existingAccount: {
    id: 'checkout_services_bouygues_sign_up_form_existing_account_link',
    defaultMessage: 'Log in to an existing Bouygues Telecom account',
  },

  // Check your personal info
  personalInfoTitle: {
    id: 'checkout_services_bouygues_sign_up_form_personal_info_title',
    defaultMessage: 'Check your personal info',
  },
  firstNameInputLabel: {
    id: 'checkout_services_bouygues_sign_up_form_first_name_input_label',
    defaultMessage: 'First name',
  },
  lastNameInputLabel: {
    id: 'checkout_services_bouygues_sign_up_form_last_name_input_label',
    defaultMessage: 'Last name',
  },
  phoneNumberInputLabel: {
    id: 'checkout_services_bouygues_sign_up_form_phone_number_input_label',
    defaultMessage: 'Billing phone number',
  },
  phoneNumberInputErrorInvalid: {
    id: 'checkout_services_bouygues_sign_up_form_phone_number_input_error_invalid',
    defaultMessage: 'Enter a valid french phone number',
  },
  phoneNumberInputClear: {
    id: 'checkout_services_bouygues_sign_up_form_phone_number_input_clear_aria_label',
    defaultMessage: 'Clear',
  },

  // Confirm you identity
  identityTitle: {
    id: 'checkout_services_bouygues_sign_up_form_identity_title',
    defaultMessage: 'Confirm your identity',
  },
  identitySubtitle: {
    id: 'checkout_services_bouygues_sign_up_form_identity_subtitle',
    defaultMessage:
      'This step is required by law so Bouygues Telecom can confirm who you are.',
  },
  genderInputLabelMale: {
    id: 'checkout_services_bouygues_sign_up_form_identity_gender_input_label_male',
    defaultMessage: 'Male',
  },
  genderInputLabelFemale: {
    id: 'checkout_services_bouygues_sign_up_form_identity_gender_input_label_female',
    defaultMessage: 'Female',
  },
  birthdateInputLabel: {
    id: 'checkout_services_bouygues_sign_up_form_identity_birthdate_input_label',
    defaultMessage: 'Date of birth',
  },
  birthdateInputErrorUnder18: {
    id: 'checkout_services_bouygues_sign_up_form_identity_birthdate_input_error_under_18',
    defaultMessage: 'You must be 18 years or older to sign up',
  },
  birthdateInputInvalidError: {
    id: 'checkout_services_bouygues_sign_up_form_identity_birthdate_input_invalid_error',
    defaultMessage: 'You birthdate is invalid',
  },
  birthDepartmentInputLabel: {
    id: 'checkout_services_bouygues_sign_up_form_identity_birth_department_input_label_input_label',
    defaultMessage: 'State of birth',
  },
  submit: {
    id: 'checkout_services_bouygues_sign_up_form_identity_submit',
    defaultMessage: 'Create account',
  },

  // Others
  inputRequired: {
    id: 'checkout_services_bouygues_sign_up_form_input_required',
    defaultMessage: 'This field is required',
  },
  legalTerms: {
    id: 'checkout_services_bouygues_sign_up_form_legal_terms',
    defaultMessage: `Les informations recueillies à l’occasion de ma commande sont enregistrées dans un fichier informatisé et traitées par Bouygues Telecom pour :
      i) gérer notre relation contractuelle avec vous pour nos produits et services et vous identifier ou authentifier ;
      ii) gérer nos opérations de prospections commerciales;
      iii) effectuer des études statistiques pour améliorer nos offres et services et/ou améliorer notre connaissance consommateur ;
      iv) lutter contre la fraude, y compris documentaire, et contre les impayés, notamment votre inscription sur le fichier Preventel si vous souhaitez souscrite à une offre mobile ;
      v) la collecte d’avis sur nos produits et services ;
      vi) gérer nos éventuels précontentieux et contentieux et
      vii) le respect de nos obligation légales.

      Pour en savoir plus sur la gestion de vos données personnelles et exercer vos droits, nous vous invitons à consulter notre {privacyPolicy, html}.`,
  },
  privacyPolicy: {
    id: 'checkout_services_bouygues_sign_up_form_privacy_policy',
    defaultMessage: 'Politique de Confidentialité',
  },
}
