<template>
  <div
    v-if="isComponentMounted"
    class="fixed inset-0 flex h-full flex-col items-center justify-center space-y-8 p-40"
  >
    <div class="h-[105px]">
      <Transition
        appear
        name="logo-image-transition"
        @after-leave="emit('animation-ended')"
      >
        <div v-if="isLoading">
          <RevIllustration
            :alt="i18n(translations.bouyguesIcon)"
            class="px-20"
            :class="'pulse'"
            :height="84"
            src="/img/bouygues/bouyguesTelecomLogoSquareAnimation.svg"
            :width="105"
          />
        </div>
      </Transition>

      <Transition name="logo-text">
        <RevIllustration
          v-if="!isLoading"
          :alt="i18n(translations.bouyguesIconLabel)"
          class="mt-2 px-10 opacity-0"
          :height="24"
          src="/img/bouygues/bouyguesTelecomLogoTextAnimation.svg"
          :width="105"
        />
      </Transition>
    </div>

    <div class="h-[25px]">
      <Transition
        appear
        enter-active-class="motion-safe:transition-opacity opacity-100 !duration-500 delay-300 ease-in-out"
        enter-from-class="!opacity-0"
        leave-active-class="motion-safe:transition-opacity opacity-0 duration-500 ease-in-out"
        leave-from-class="opacity-100"
      >
        <h3 v-if="isLoading" class="heading-3 max-w-[544px] text-center">
          {{ i18n(translations.title) }}
        </h3>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Loading.translations'

const i18n = useI18n()
const isComponentMounted = ref(false)
const setTimeoutId = ref()
const isMinTimeElapsed = ref(false)

const props = defineProps<{
  pending: boolean
}>()

onMounted(() => {
  isComponentMounted.value = true
  setTimeoutId.value = setTimeout(() => {
    isMinTimeElapsed.value = true
  }, 3000)
})

onUnmounted(() => {
  clearTimeout(setTimeoutId.value)
})

const emit = defineEmits(['animation-ended'])

const isLoading = computed(() => {
  return !isMinTimeElapsed.value || props.pending
})
</script>

<style>
.pulse {
  animation: pulse-animation 3s 0.5s infinite;
}

.logo-text-enter-active {
  animation: logo-text-animation 2s;
}

.logo-image-transition-enter-from {
  transform: scale(0);
}
.logo-image-transition-enter-active {
  transition: transform 0.5s;
}
.logo-image-transition-leave-active {
  animation: logo-image-scale-exit-animation 2s 2s;
}

@keyframes logo-image-scale-exit-animation {
  100% {
    transform: scale(300);
  }
}

@keyframes logo-text-animation {
  0%,
  17%,
  100% {
    opacity: 0;
  }
  34%,
  83% {
    opacity: 1;
  }
}

@keyframes pulse-animation {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}
</style>
