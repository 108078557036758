<template>
  <div class="m-auto max-w-[544px]">
    <SignUpForm v-if="showSignUpForm" :user />
    <LoginForm
      v-else
      :email="user.email"
      @sign-up-link-clicked="showSignUpForm = true"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useUser } from '../../../../composables/useUser'

import LoginForm from './components/LoginForm/LoginForm.vue'
import SignUpForm from './components/SignUpForm/SignUpForm.vue'

const user = useUser()

const props = defineProps<{ isAccountExisting: boolean }>()

const showSignUpForm = ref(!props.isAccountExisting)
</script>
