<template>
  <h3 class="heading-3 mb-16">
    {{ i18n(translations.title, { email }) }}
  </h3>

  <RevCard class="mb-32 p-24">
    <BouyguesLogo class="mb-8" />

    <div class="mb-24">{{ i18n(translations.loginInformation) }}</div>
    <RevButton
      :to="authorizeUrl.href"
      :tracking="{
        zone: 'bouygues_sign_up',
        name: 'login',
        page_type: route.name as string,
      }"
      variant="primary"
    >
      {{ i18n(translations.login) }}
    </RevButton>
  </RevCard>

  <RevLink
    :tracking="{
      zone: 'bouygues_sign_up',
      name: 'switch_to_sign_up',
      page_type: route.name as string,
    }"
    @click="() => emit('sign-up-link-clicked')"
  >
    {{ i18n(translations.signUpLink) }}
  </RevLink>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevLink } from '@ds/components/Link'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'

import { useBouyguesAuthorize } from '../../../../composables/useBouyguesAuthorize'

import translations from './LoginForm.translations'

const { authorizeUrl } = useBouyguesAuthorize()
const i18n = useI18n()
const route = useRoute()

defineProps<{
  email: string
}>()

const emit = defineEmits(['sign-up-link-clicked'])
</script>
