export default {
  title: {
    id: 'checkout_services_bouygues_login_form_title',
    defaultMessage:
      'It looks like you have a Bouygues Telecom account with your email address {email}',
  },
  loginInformation: {
    id: 'checkout_services_bouygues_login_form_login_information',
    defaultMessage:
      'Nice! Now, we’ll send you to the Bouygues Telecom portal. Just log in with your existing account info.',
  },
  login: {
    id: 'checkout_services_bouygues_login_form_login_button',
    defaultMessage: 'Log in',
  },
  signUpLink: {
    id: 'checkout_services_bouygues_login_form_sign_up_link',
    defaultMessage: 'Create a new Bouygues Telecom account',
  },
}
