export default {
  title: {
    id: 'checkout_services_bouygues_loading_title',
    defaultMessage: 'Time to set up your Bouygues Telecom account.',
  },
  bouyguesIcon: {
    id: 'checkout_services_bouygues_login_form_bouygues_icon',
    defaultMessage: 'Bouygues icon',
  },
  bouyguesIconLabel: {
    id: 'checkout_services_bouygues_login_form_bouygues_icon_label',
    defaultMessage: 'Bouygues icon label',
  },
}
